import styled from "styled-components";
export const Title=styled.h3`
color:${props=>props.color?props.color:"black"};
text-align: center;
font-size: 30px;
margin:0px;
/* margin-top: 8px; */

`
export const Btn=styled.div`
display: block;
padding: 10px 30px;
max-width: max-content;
cursor: pointer;
background:${props=>props.color?props.color:"black"};
border: none;
color: white;
margin-left: auto;
margin-right: auto;
border-radius: 20px;
margin-bottom: 10px;
transition: .24s ease;
&:hover{
    background:${props=>props.hover?props.hover:"red"};

}
`
