import Logins from "./pages/Login";
import {Switch,BrowserRouter as Router,Route} from 'react-router-dom'
import { useEffect, useState } from "react";
import {useSelector,useDispatch} from 'react-redux'
import {check,closesnack} from './actions/action'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components'

import { Snackbar,IconButton } from "@mui/material";
import Sidebar from "./components/Sidebar";
import Message from './pages/Message'
import Stats from './pages/Stats'

import Members from './pages/Members'
import Service from './pages/Service'
import Service2 from './pages/Service2'
import Events from './pages/Events'
import Announcement from './pages/Announcement'
import PhotoGalleryBrowse from './pages/PhotoGalleryBrowse'
import News from "./pages/News";
import Carousel from "./pages/Carousel";
import PhotoGallery from "./pages/PhotoGallery";
import Files from "./pages/Files";
import About from "./pages/About";
import Comment from "./pages/Comment";
import Form from "./pages/Form";
function App() {
  const Login=useSelector(state=>state.login)
  const loading=useSelector(state=>state.loading)
  const open=useSelector(state=>state.open)
  const message=useSelector(state=>state.message)
  const dispatch=useDispatch()
  const handleClose =()=>{
    dispatch(closesnack())
  }

  useEffect(()=>{
    dispatch(check())

  },[dispatch])
  return (
<div>
  <Snackbar anchorOrigin={{vertical:'top',horizontal:'right'}} open={open} autoHideDuration={1500} onClose={handleClose} message={message} action={
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
          <CloseIcon fontSize="small"/>
      </IconButton>
    </React.Fragment>
  }/>
  {
    loading?<>{"Loading...."}</>:
   
    <Router>
      <Container>

      {
        !Login?<>
        <Logins/>
        </>:
        <>
        <Sidebar/>
        <Route path='/message' component= {Message} exact/>
        <Route path='/announcement' component= {Announcement} exact/>
        <Route path='/stats' component= {Stats} exact/>
        <Route path='/service/saving' component= {Service} exact/>
        <Route path='/service/loan' component= {Service2} exact/>
        <Route path='/news' component= {News} exact/>
        <Route path='/images' component= {Carousel} exact/>
        <Route path='/about' component= {About} exact/>
        <Route path='/gallery' component= {PhotoGallery} exact/>
        <Route path='/gallery/:id' component= {PhotoGalleryBrowse} exact/>
        <Route path='/downloads' component= {Files} exact/>
        <Route path='/services' component= {Service} exact/>
        <Route path='/events' component= {Events} exact/>
        <Route path='/board' exact>
          <Members title="Board Members" type="board"/>
        </Route>
        <Route path='/staffs' exact>
          <Members title="Staff Members" type="staff"/>
          </Route>
        <Route path='/comments' exact>
          <Comment/>
          </Route>
          
        <Route path='/applicationform' exact>
          <Form/>
          </Route>

        </>
        
      }
      </Container>

    </Router>
  }

</div>
  );
}

const Container=styled.div`
width: 100%;
min-height: 100vh;
display: flex;
position:relative ;


`
export default App;
