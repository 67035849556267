import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import MessageComponent from '../components/MessageComponent'
import {Title} from '../components/styles'
import { host } from '../constant'
function Message() {
    const [loading,setLoading]=useState(false)
    const [data,setData]=useState([])
    const token=useSelector(state=>state.token)
    useEffect(()=>{
        setLoading(true)
        fetch(`${host}/admin/message`,{
            method:'get'

        }).then(res=>res.json()).then(data=>{
           
            setData(data.messages)
            setLoading(false)

        })
        .catch(err=>{
            setData([])
            setLoading(false)
        })
    },[])
 
    return (
        <Container>
            {
                loading?<></>:
                <>  
                <Title>
                    Message

                </Title>
                <MessageContainer>

                {   
                data.map((item,key)=>(

                    <MessageComponent key={key} data={item}/>
                    ))

                }
                </MessageContainer>
                </>
            }
        </Container>
    )
}

export default Message
const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;
const MessageContainer=styled.div`
display: flex;
flex-direction: column;
padding:20px 40px;
`

