import React, { useEffect, useState,useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components'
import { Btn, Title } from '../components/styles'
import { host } from '../constant';
import {snackmsg,logout} from '../actions/action'
import { Modal } from '@mui/material'
import CarouselImage from '../components/CarouselImage';
function PhotoGalleryBrowse(props) {
    const [open,setOpen]=useState(false)
    const [data,setData]=useState([])
    const [title,setTitle]=useState('')
    const [caption,setCaption]=useState('')
    const token=useSelector(state=>state.token)
    const dispatch=useDispatch()
    const fileRef=useRef()
    const uploadHandler=()=>{
        if(fileRef.current.files.length>0){
            let formdata=new FormData()
            var i;
            for(i=0;i<fileRef.current.files.length;i++){

              formdata.append('post-image',fileRef.current.files[i]);
            }
            formdata.append('caption',caption);
            formdata.append('id',props.match.params.id);
            
            fetch(`${host}/admin/addimage`,{
              method:"post",
              headers:{
                authorization:`Bearer ${token}`
              },
              body:formdata
            }).then(async(res)=>{
              if(res.status==200){
                dispatch(snackmsg("New Images Added"))
                handleClose()
                const response=await res.json()
                setData(data=>[...data,...response.data])

                // setReload(reload=>!reload)
              }
              else if(res.status==401){
                dispatch(snackmsg("Session Expired"))
                dispatch(logout())
              }
            }).catch(err=>{
              dispatch(snackmsg("Network Error"))
      
            })
          }
        
    }
    const handleClose=()=>{
        setOpen(false)
        if(fileRef.current){


            fileRef.current.files=null
          }
    }
    const updateHandler=()=>{
        fetch(`${host}/admin/gallery/${props.match.params.id}`,{
            method:'put',
            headers:{
                'Authorization':`Bearer ${token}`,
                'Content-Type':'application/json'
            },
            body:JSON.stringify({title})
        }).then(res=>{
            if(res.status==200){
                dispatch(snackmsg("Title Updated"))
            }
            else if(res.status==401){
                dispatch(snackmsg("Session Expired"))
                dispatch(logout())
            }
            else{
                dispatch(snackmsg("UnExpected Error Occured"))
            }
        })
        .catch(err=>{
            dispatch(snackmsg("UnExpected Error Occured"))

        })
    }
    useEffect(()=>{
fetch(`${host}/admin/gallery/${props.match.params.id}`,{
    method:'get'
})
.then(res=>res.json()).then(result=>{
    // console.log(result)
    setTitle(result.data.title)
    setData(result.data.images)

})

    },[])
    return (
        <Container>
            <Title>Album: {title}</Title>
            <input style={{padding:"10px",margin:"5px 80px 10px",}} placeholder={"Title"} value={title} onChange={e=>setTitle(e.target.value)} type="text" />
            <div style={{display:'flex',justifyContent:'center'}}>
            <Btn style={{margin:'5px',marginBottom:"10px"}} onClick={updateHandler}>Update </Btn>
            <Btn style={{margin:'5px',marginBottom:"10px"}} color='green' onClick={()=>setOpen(true)}>+Add Image </Btn>
            </div>
            <br></br>
            {
                data?.map((item,key)=>(<CarouselImage data={item} key={key} setData={setData}/>))
            }
            <Modal
        style={{
          border: "none",
          outline: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <Card>
          <Title>Add Image</Title>
          <UploadContainer>
            <input style={{padding:"5px 5px",margin:"5px 0 30px",width:'100%'}} placeholder={"Caption"} value={caption} onChange={e=>setCaption(e.target.value)} type="text" />
            <input ref={fileRef} type="file"  multiple/>
            <Btn style={{ marginTop: "10px" }} onClick={uploadHandler}>Upload</Btn>
          </UploadContainer>
        </Card>
      </Modal>
         
        </Container>
    )
}
const Container=styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

const Card = styled.div`
  padding: 10px 20px;
  width: 500px;
  height: auto;
  background: white;
  outline: none;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
`;
const UploadContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export default PhotoGalleryBrowse
