import { Modal } from '@mui/material'
import React,{useState,useRef} from 'react'
import { useDispatch,useSelector } from 'react-redux'
import styled from 'styled-components'
import { host } from '../constant'
import {Btn,Title} from './styles'
import {logout,snackmsg} from '../actions/action'
import TextBox from './TextBox'
function MessageComponent(props) {
    const [from,setForm]=useState(props.data.messageFrom) 
    const dispatch=useDispatch()
    const [message,setMessage]=useState(props.data.message) 
    const [image,setImage]=useState(props.data.image)
    const token=useSelector(state=>state.token)
    const [open,setOpen]=useState(false)
    const fileRef=useRef(null)
    const handleClose = () => {
        setOpen(false)
        if(fileRef.current){
        
        
          fileRef.current.files=null
        }
        
          };
          const uploadHandler=()=>{
            if(fileRef.current.files[0]){
              let formdata=new FormData()
              formdata.append('picture',fileRef.current.files[0]);
            //   formdata.append('',fileRef.current.files[0]);
              
              fetch(`${host}/admin/updatemessage/${props.data._id}/image`,{
                method:"put",
                headers:{
                  authorization:`Bearer ${token}`
                },
                body:formdata
              }).then(async(res)=>{
                if(res.status==200){
                
                  dispatch(snackmsg("Image Uploaded"))
                  var response=await res.json();
                  setImage(response.link)
                  handleClose()
                }
                else if(res.status==401){
                  dispatch(snackmsg("Session Expired"))
                  dispatch(logout())
                }
              }).catch(err=>{
                dispatch(snackmsg("Network Error"))
        
              })
            }
            
          }
          const updateHandler=()=>{
            fetch(`${host}/admin/updatemessage/${props.data._id}`,{
                method:"put",
                headers:{
                  authorization:`Bearer ${token}`,
                  'Content-Type':'application/json'
                },
                body:JSON.stringify({
                    messageFrom:from,
                    message:message

                })
              }).then(async(res)=>{
                if(res.status==200){
                
                  dispatch(snackmsg("Content Updated"))

                }
                else if(res.status==401){
                  dispatch(snackmsg("Session Expired"))
                  dispatch(logout())
                }
              }).catch(err=>{
                dispatch(snackmsg("Network Error"))
        
              })

          }
    return (
        <Container >
            <ImageChange>
                
            <ImageDiv>
                {
                    image&& <Image src={host+image}></Image>
                }

            </ImageDiv>
            <Buttons>
                <Btn onClick={()=>{setOpen(true)}}>Change</Btn>
            </Buttons>
            </ImageChange>
            <ContentChange>
                <Form>
                    <Input value={from} onChange={e=>setForm(e.target.value)}/>
                </Form>
                <Form>
               <TextBox currentValue={message} onChange={setMessage} height={400}/>
                    {/* <TextArea value={message} rows="15" onChange={e=>setMessage(e.target.value)}/> */}
                </Form>
                <Btn onClick={updateHandler} color="teal" hover="green">Update</Btn>

            </ContentChange>
            <Modal
        style={{
          border: "none",
          outline: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <Card>
          <Title>Upload Image</Title>
          <UploadContainer>
            <input ref={fileRef} type="file" />
            <Btn style={{ marginTop: "10px" }} onClick={uploadHandler}>Upload</Btn>
          </UploadContainer>
        </Card>
      </Modal>
        </Container>
    )
}

const Container=styled.div`
display: flex;
margin-bottom:20px;
justify-content: center;
`
const ImageDiv=styled.div`
margin:0 auto;
width:187px;
height: 187px;
position: relative;
background:grey;
border:1px solid black;
:after{
    content:'Image';
    position:absolute;
    top:50%;
    color:black;
    left:50%;
    transform: translate(-50%,-50%);


}

`
const ImageChange=styled.div`
display: flex;
flex-direction: column;

`
const Buttons=styled.div`
margin-top: 10px;
display: flex;
justify-content: space-between;

`
const Input=styled.input`
width:100%;
padding:10px 5px;
`
const TextArea=styled.textarea`
width: 100%;
resize: none;
padding:10px 5px;
`
const ContentChange=styled.div`
margin-left: 30px;
width:500px;
`
const Form=styled.div`
width:100%; 
margin-bottom: 20px;
`
const Image=styled.img`
position: absolute;
top:0;
left: 0;
width:100%;
height: 100%;
z-index:2;
object-fit: cover;
`
const Card = styled.div`
  padding: 10px 20px;
  width: 500px;
  height: auto;
  background: white;
  outline: none;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
`;
const UploadContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export default MessageComponent
