import { Modal } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import {Btn, Title} from '../components/styles'
import { host } from '../constant'
import { logout, snackmsg } from "../actions/action";
import DeleteIcon from '@mui/icons-material/Delete';
import Calendar from "@sbmdkl/nepali-datepicker-reactjs";
import "@sbmdkl/nepali-datepicker-reactjs/dist/index.css";
import { CalendarToday } from '@mui/icons-material'
function Events() {
    const [open,setOpen]=useState(false)
    const [event,setEvent]=useState([])
 
    
    const [title,setTitle]=useState('')
    const [date,setDate]=useState('')
    const [types,setTypes]=useState('holiday')
    const [id,setId]=useState(null)
    const [type,setType]=useState('add')
    const [reload,setReload]=useState(true)

    const deleteHandler=(current)=>{
        if(window.confirm(`Do you want to remove ${current.title}?`)){
            fetch(`${host}/admin/event/${current._id}`,{
                method:'delete',
                headers:{
                    "Authorization":`Bearer ${token}`
                }
            }).then(async(res)=>{
                if(res.status==200){
                    const response=await res.json()
                  dispatch(snackmsg(response.message))
                    setReload(true)
                    handleClose()
    
                }
                else if(res.status==401){
                  dispatch(snackmsg("Session Expired"))
                dispatch(logout())
                }
              
                
              }).catch(err=>{
                dispatch(snackmsg("Network Error"))
                
              })
    


        }
    }
    const handleClose=()=>{
        setOpen(false);
      
     
          
    }
    const dispatch=useDispatch()
    const token=useSelector(state=>state.token)
    const submitHandler=()=>{
       if(title.trim()==''||date.trim()==''){
        return alert('title cannot be empty')
       }
       
        fetch(`${host}/admin/event${type=='add'?'':`/${id}`}`,{
            method:type=='add'?'post':'put',
            headers:{
                'Authorization':`Bearer ${token}`,
                'Content-Type':'application/json'
            },
            body:JSON.stringify({
                title,
                date,
                type:types
            })
       
        }).then(async(res)=>{
            if(res.status==200){
              const response=await res.json()
              dispatch(snackmsg(response.message))
                setReload(true)
                handleClose()

            }
            else if(res.status==401){
              dispatch(snackmsg("Session Expired"))
            dispatch(logout())
            }
            else if(res.status==406){
                dispatch(snackmsg("Date is Passed"))
         
              }
          }).catch(err=>{
            dispatch(snackmsg("Network Error"))
            
          })

    }
    useEffect(()=>{
        if(reload){

       
        fetch(`${host}/admin/event`,{
            method:'get'
        }).then(res=>res.json())
        .then(data=>{
            setEvent(data.events)
          
       
        })
        setReload(false);
    }
    },[reload])
    useEffect(()=>{



        if(open && date!=""){
            setTimeout(()=>{
document.querySelector('.date-picker-xvm').value=date;
            },100)
        }
    },[open,date])
 
    const process_date=(date)=>{
        return `${date.en.year}/${date.en.month}/${date.en.day}`
    }
    
    return (
        <Container>
            <Title>Events</Title>
            <AddEventBtn onClick={()=>{setOpen(true);setType('add');setTitle('');setTypes('holiday');setDate('')}}>+Add Event</AddEventBtn>
            <NewsContainer>
                {
                    event.map((item,key)=>(

                <ElmntDiv key={key}>
                    <Elmnt onClick={()=>{setType('edit');setTitle(item.title);setDate(process_date(item.date));setTypes(item.type);setOpen(true);setId(item._id);}}>
                        <div>{item.title}</div>
                    <Date color={item.type=='holiday'?'red':'blue'}>
                  
                    <CalendarToday style={{display:"flex",alignItems:'center'}}/>
                        {`${item.date.en.day} ${item.date.en.strMonth} ${item.date.en.year}  `}
                    </Date>
                    </Elmnt>
                    
                    <Delete><DeleteIcon onClick={()=>deleteHandler(item)}/></Delete>
                </ElmntDiv>
                    ))
                }

            </NewsContainer>
            <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
      >
          <Card>
              <Title>{type=="add"?"Create Event":`Edit Event: ${title}`}</Title>
              <Form>
                  <Input value={title} onChange={e=>setTitle(e.target.value)} placeholder="Title" type="text"></Input>
              </Form>
              <Form>
    
                 <select value={types} onChange={e=>setTypes(e.target.value)}>
                    <option>holiday</option>
                    <option>event</option>
                 </select>
                  
              </Form>
              <Form>
              <Calendar hideDefaultValue={true} className="date-picker-xvm" dateFormat="YYYY/MM/DD" language="en" onChange={({bsDate,adDate})=>{setDate(bsDate)}} theme="deepdark" />
        
              </Form>
               
      
              <Btn onClick={()=>submitHandler()}>Submit</Btn>

          </Card>
          </Modal>
        </Container>
    )
}
const Form=styled.div`
width:100%; 
margin-bottom: 20px;
`
const TextArea=styled.textarea`
width: 100%;
resize: none;
padding:10px 5px;
`
const Input=styled.input`
width:100%;
padding:10px 5px;
`
const Card = styled.div`
  outline: none;
  padding: 14px 15px;
  background: white;
  width: 70%;
  /* width: 500px; */
  border-radius: 10px;
  max-height: 90%;
  overflow-y: auto;
  padding-bottom: 220px;
`;
const Container=styled.div`
 flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const NewsContainer=styled.div`

`
const AddEventBtn=styled.div`
padding: 8px 10px;
color:white;
background: green;
cursor: pointer;
margin-right:20px; 
margin-left:auto;
border-radius: 10px;
:hover{
    background:red;
}

`
const ElmntDiv=styled.div`
margin:8px auto;
display: flex;
width:80%;
border:1px solid grey;

`
const Elmnt=styled.div`
flex:1;
display:flex;
font-weight: bold;
cursor: pointer;
padding: 6px 8px;
:hover{
    background: #ebe7df;
}
`
const Delete=styled.div`
margin-left:auto;
color:red;
cursor:pointer;
width:30px;
display: flex;
justify-content: center;
align-items: center;
:hover{
    color:tomato;
}

`
export default Events
const Date=styled.div`
margin-left:auto;
margin-right:10px;
display:flex;
align-items:center;
color:${props=>props.color};
`