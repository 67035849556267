
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Images from "../components/Image";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import { Btn, Title } from "../components/styles";
import Team from "../components/Member";
import { host } from "../constant"
import {useDispatch,useSelector} from 'react-redux'
import { logout, snackmsg } from "../actions/action";
import { Modal } from "@mui/material";

function Member(props) {
  const dispatch=useDispatch()
  const token=useSelector(state=>state.token)
  const [loading,setLoading]=useState(true);
  const [open,setOpen]=useState(false);
  const [open2,setOpen2]=useState(false);
  const [data,setData]=useState([])
  const [reload,setReload]=useState(true)
  const [currentData,setCurrentData]=useState(null)
  const handleDelete=async(id,name)=>{
    if(window.confirm(`Do you want to remove ${name}?`)){

      fetch(`${host}/admin/deleteteammember/${id}`,{
        method:"delete",
        headers:{
          authorization:`Bearer ${token}`
        }
      }).then(async(res)=>{
        if(res.status==200){
          const response=await res.json()
          dispatch(snackmsg(response.message))
          setData(data=>data.filter(dat=>{
            return dat._id!=id
        }))
     
          // window.location.reload(true)
        }
        else if(res.status==401){
          dispatch(snackmsg("Session Expired"))
        dispatch(logout())
        }
      }).catch(err=>{
        dispatch(snackmsg("Network Error"))
        
      })
    }
  }
  const handleopen=async(data)=>{
    await setCurrentData(data)
    // console.log(data)
    setOpen2(true)


    
    
  }
  const updateOrder=async(id,factor)=>{
    const order=await fetch(`${host}/admin/member/order`,{
      method:'put',
      headers:{
        'Content-Type':'application/json',
        'Authorization':`Bearer ${token}`
      },
      body:JSON.stringify({
        id,factor,type:props.type
      })
    })
    if(order.status==200){
      setReload(true)

    }
   
  }

  const TableRows = (props) => {
    return (
      <tr>
        <td>{props.name}</td>
        <td>{props.role}</td>
        <td>
          <Images
            width="200px"
            src={`${host}${props.image}`}
            onError={e=>{e.target.src=""}}
          />
        </td>
        <td style={{ display: "flex", justifyContent: "center", gap: "8px" }}>
          <Btn style={{ margin: 0 }} onClick={()=>handleopen(props)}>Edit</Btn>
          <Btn style={{ margin: 0 }} onClick={()=>handleDelete(props._id,props.name)}>Delete</Btn>
          <Btn2 color="green" hover="lightgreen" onClick={()=>{updateOrder(props._id,-1)}}>
          <ArrowDropUpIcon/>
            </Btn2>
            <Btn2 onClick={()=>{updateOrder(props._id,+1)}}>

          <ArrowDropDownIcon/>
          </Btn2>
        </td>
      </tr>
    );
  };
  const handleClose=()=>{
    setOpen(false)
    setOpen2(false)
  }
  
  useEffect(()=>{
    if(reload){

      fetch(`${host}/admin/members/${props.type}`,{
        method:"get"
      }).then(res=>res.json()).then(result=>{
        // console.log(result)
        setData(result.data)
        setReload(false)
      })
    }

  },[reload])
  return (
    <Container>
      <Title style={{ color: "red", paddingTop: "10px" }}>{props.title}</Title>
      <TeamContainer>
        <Btn style={{ marginRight: "0" }} onClick={()=>setOpen(true)}>+ Add New</Btn>
        <Table>
          <tr>
            {/* <th>S.N</th> */}
            <th>Name</th>
            <th>Role</th>
            {/* <th>Role</th> */}
            <th>Photo</th>
            <th>Actions</th>
          </tr>
          {data.map((item, key) => (
            <TableRows key={key} {...item} />
          ))}
        </Table>
      </TeamContainer>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <Card style={{ outline: "none" }}>
        <Team title="Add Member" method="post" type={props.type} update={setData} endpoint="addnewmember" btntext="Add" mode="add" close={handleClose}/>
        </Card>
      </Modal>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open2}
        onClose={handleClose}
      >
        <Card style={{ outline: "none" }}>
        <Team title="Edit Member" method="put" type={props.type} update={setData} endpoint={`editmember/${currentData?currentData._id:""}`} data={currentData} close={handleClose} btntext="Save" mode="edit"/>
        </Card>
      </Modal>
    </Container>
  );
}

export default Member;
const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const TeamContainer = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding: 10px 20px;
  
  /* background: linear-gradient(to right, red, blue); */
`;
const Table = styled.table`
  /* display: block; */
  /* text-align:center; */
  /* flex: 1; */
  /* height:min-content */
  padding: 5px 12px;
`;


const Card = styled.div`
  outline: none;
  padding: 14px 15px;
  background: white;
  /* width: 80%; */
  width: 500px;
  border-radius: 10px;
  max-height: 90%;
  overflow-y: auto;
`;
const Btn2=styled.div`
width:30px;
height:30px;
display: flex;
align-items: center;
justify-content: center;
border-radius: 50%;
/* padding: 4px; */
background:${props=>props.color?props.color:'red'};
color:white;
:hover{
  cursor:pointer;
  background:${props=>props.hover?props.hover:'tomato'};
}
`