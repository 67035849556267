import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Btn, Title } from '../components/styles'
import { host } from '../constant'
import {snackmsg,logout} from '../actions/action'
import { Modal } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download';
import { DeleteForever } from '@mui/icons-material'
function Files() {
    const [active,setActive]=useState('bulletin')
    const [data,setData]=useState([])
    const [open,setOpen]=useState(false)
    const [open2,setOpen2]=useState(false)
    const [current,setCurrent]=useState(null)
    const [uploading,setUploading]=useState(false)
    const [title,setTitle]=useState('')
    const [title2,setTitle2]=useState('')
    const fileRef=useRef()
    const fileRef2=useRef()
    const handleClose=()=>{
        setTitle('')
        setOpen(false)
        setOpen2(false)
        if(fileRef.current){


            fileRef.current.files=null
         
          }
        if(fileRef2.current){


            fileRef2.current.files=null
         
          }

    }
    const uploadHandler=()=>{
      if(uploading) return
      setUploading(true)
        if(fileRef.current.files[0]){
            let formdata=new FormData()
            formdata.append('file',fileRef.current.files[0]);
            formdata.append('title',title);
            
            fetch(`${host}/admin/files/${active}`,{
              method:"post",
              headers:{
                authorization:`Bearer ${token}`
              },
              body:formdata
            }).then(async(res)=>{
                console.log(res.status)
              if(res.status==200){
                dispatch(snackmsg("New File Added"))
                handleClose()
                setUploading(false)
                const response=await res.json()
                setData(data=>[...data,response.data])

               
              }
              else if(res.status==401){
                dispatch(snackmsg("Session Expired"))
                dispatch(logout())
              }
            }).catch(err=>{
               setUploading(false)
              dispatch(snackmsg("Network Error"))
      
            })
          }
          

    }
    const updateHandler=()=>{
     fetch(`${host}/admin/files/title/${current._id}`,{
         method:'put',
         headers:{
             'Authorization':`Bearer ${token}`,
             'Content-Type':'application/json'
         },
         body:JSON.stringify({title:title2})
     })   
     .then(async(res)=>{
         if(res.status==200){
            dispatch(snackmsg("Title Updated"))
            handleClose()
            const response=await res.json()
            setData(data=>data.map(dat=>{
                if(dat._id==current._id){
                    return response.data
                }
                else{
                    return dat
                }
            }))

         }
         else if(res.status==401){
            dispatch(snackmsg("Session Expired"))
            dispatch(logout())

         }else{
            dispatch(snackmsg("Network Error"))

         }
     })
     .catch(err=>{
               
        dispatch(snackmsg("Network Error"))

      })
    }
    const deleteHandler=(id)=>{
        if(window.confirm('Do you want to delete selected file?')){
     fetch(`${host}/admin/files/${id}`,{
         method:'delete',
         headers:{
             'Authorization':`Bearer ${token}`
         },
   
     })   
     .then(async(res)=>{
        
         if(res.status==200){
            dispatch(snackmsg("File Removed"))
            handleClose()
            setData(data=>data.filter(dat=>{

return dat._id!=id
            }))

         }
         else if(res.status==401){
            dispatch(snackmsg("Session Expired"))
            dispatch(logout())

         }else{
            dispatch(snackmsg("Network Error"))

         }
     })
     .catch(err=>{

        dispatch(snackmsg("Network Error"))

      })
    }
    }
    const uploadHandler2=()=>{
        if(fileRef2.current.files[0]){
            let formdata=new FormData()
            formdata.append('file',fileRef2.current.files[0]);
       
            
            fetch(`${host}/admin/files/upload/${current._id}`,{
              method:"put",
              headers:{
                authorization:`Bearer ${token}`
              },
              body:formdata
            }).then(async(res)=>{
              if(res.status==200){
                dispatch(snackmsg("File Updated"))
                handleClose()
                const response=await res.json()
                setData(data=>data.map(dat=>{
                    if(dat._id==current._id){
                        return response.data
                    }
                    else{
                        return dat
                    }
                }))

               
              }
              else if(res.status==401){
                dispatch(snackmsg("Session Expired"))
                dispatch(logout())
              }
            }).catch(err=>{
               
              dispatch(snackmsg("Network Error"))
      
            })
          }
        
        
    }
    
    const dispatch=useDispatch()
    const token=useSelector(state=>state.token)
    
    useEffect(()=>{
        fetch(`${host}/admin/files/${active}`,{
            method:'get'
        }).then(res=>res.json()).then(result=>{
            setData(result.data)
        })
    },[active])
    return (
        <Container>
            <Title>Downloads</Title>
            <Tabs>
                <Tab className={active=="bulletin"&&"active"} onClick={()=>setActive("bulletin")}>Bulletins</Tab>
                <Tab className={active=="applicationform"&&"active"} onClick={()=>setActive("applicationform")}>Applications</Tab>
                <Tab className={active=="report"&&"active"} onClick={()=>setActive("report")}>Reports</Tab>
                <Tab className={active=="calender"&&"active"} onClick={()=>setActive("calender")}>Calenders</Tab>

            </Tabs>
            <Btn onClick={()=>setOpen(true)} style={{marginRight:20}}>+Add</Btn>
            {
                data.map((item,key)=><ListContainer key={key}>
                    <List onClick={()=>{setOpen2(true);setCurrent(item);setTitle2(item.title)}}>{item.title}</List>
                    <Delete><a href={`${host}${item.url}`} download><DownloadIcon/></a> </Delete>
                    <Delete><DeleteForever onClick={()=>deleteHandler(item._id)}/></Delete>


                </ListContainer>)
            }
            <Modal
        style={{
          border: "none",
          outline: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <Card>
          <Title>Add {active=="bulletin"?"Bulletin":active=="applicationform"?"ApplicationForm":active=="calender"?"Calender":"Report"}</Title>
          <UploadContainer>
            <input style={{padding:"5px 5px",margin:"5px 0 30px",width:'100%'}} placeholder={"Title"} value={title} onChange={e=>setTitle(e.target.value)} type="text" />
            <input ref={fileRef} type="file" />
            <Btn style={{ marginTop: "10px" }} onClick={uploadHandler}>Upload</Btn>
          </UploadContainer>
        </Card>
      </Modal>
            <Modal
        style={{
          border: "none",
          outline: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open2}
        onClose={handleClose}
      >
        <Card>
          <Title>Edit {active=="bulletin"?"Bulletin":active=="applicationform"?"ApplicationForm":active=="calender"?"Calender":"Report"}</Title>
          <UploadContainer>
            <input style={{padding:"5px 5px",margin:"5px 0 30px",width:'100%'}} placeholder={"Title"} value={title2} onChange={e=>setTitle2(e.target.value)} type="text" />
            <Btn style={{ marginTop: "10px" }} onClick={updateHandler}>Update Title</Btn>
            <input ref={fileRef2} type="file" />
            <Btn style={{ marginTop: "10px" }} onClick={uploadHandler2}>Upload</Btn>
          </UploadContainer>
        </Card>
      </Modal>
        </Container>
    )
}
const Container=styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
 const Tab=styled.div`
  color:white;
      background:grey;
  cursor:pointer;
  border-radius:12px;
  padding: 5px 14px;
  margin:5px 5px;
  &.active{
      font-weight: bold;
      color:black;
      background:none;
      color:white;
  background:black;
  }
  
  `
const Tabs=styled.div`
margin-top: 40px;
margin-right: 20px;
display: flex;
justify-content: flex-end;
`
const Card = styled.div`
  padding: 10px 20px;
  width: 500px;
  height: auto;
  background: white;
  outline: none;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
`;
const UploadContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;
const ListContainer=styled.div`
margin:8px auto;
display: flex;
width:80%;
border:1px solid grey;

`
const List=styled.div`
flex:1;
font-weight: bold;
cursor: pointer;
padding: 6px 8px;
:hover{
    background: #ebe7df;
}
`
const Delete=styled.div`
color:red;
cursor:pointer;
width:30px;
display: flex;
justify-content: center;
align-items: center;
:hover{
    color:tomato;
}

`

export default Files
