import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Btn, Title } from "../components/styles";
import { host } from "../constant";
import {snackmsg,logout} from '../actions/action'
import {useSelector,useDispatch} from 'react-redux'
function Service() {
  const [current, setCurrent] = useState(null);
  const [active, setActive] = useState("loan");
  const [services, setServices] = useState([]);
  const token=useSelector(state=>state.token)
  const dispatch=useDispatch()

  const AddHandler = (e) => {
    console.log(e);
    const elm = document.querySelector('#template').querySelector(".template").cloneNode(true);
    elm.classList.add("removable")
    elm.querySelector(".btn1").addEventListener("click", (e) => {
      AddHandler(e);
    });
    elm.querySelector(".btn2").addEventListener("click", (e) => {
      DeleteHandler(e);
    });

    document
      .querySelector(".container")
      .insertBefore(elm, e.target.parentNode.nextSibling);
  };
  const DeleteHandler = (e) => {
    if (window.confirm("Delete Selected Row?")) {
      e.target.parentNode.remove();
    }
  };
  const updateHandler = async() => {
   var data=[]
   var sn=document.getElementsByName('sn')
   var title=document.getElementsByName('title')
   var interest=document.getElementsByName('interest')
   var i;
   for(i=0;i<sn.length;i++){
       if(title[i].value){

           await data.push({sn:sn[i].value,title:title[i].value,interest:interest[i].value})
       }
   }
   fetch(`${host}/admin/services/${active}`,{
       method:'put',

       headers:{
        'Content-Type':'application/json',
        'Authorization':`Bearer ${token}`
       },
       body:JSON.stringify({data})
   }).then(async(res)=>{
    if(res.status==200){
      dispatch(snackmsg("Updated "))
     
      const response=await res.json()
      // setServices(response.data.data)
      // console.log(response.data)

      // setReload(reload=>!reload)
    }
    else if(res.status==401){
      dispatch(snackmsg("Session Expired"))
      dispatch(logout())
    }
    else if(res.status==404){
        dispatch(snackmsg("404 Not Found"))
    }
    else{
        dispatch(snackmsg("UnExpected Error Occured"))

    }
  }).catch(err=>{
    dispatch(snackmsg("Network Error"))

  })


  };
  useEffect(async() => {
    var data=document.getElementsByClassName('removable');
    var i;
    for(i=0;i<data.length;i++){
      try{

        await data[i].remove()  
      }
      catch(err){
        
      }
    }


    fetch(`${host}/api/services/${active}`, { method: "get" })
      .then((res) => res.json())
      .then((result) => {setServices(result.data.data)
   
    });
  }, [active]);
  return (
    <Container>
      <template id="template">
        <Form className="template">
          <Input name="sn" placeholder="SN" style={{ width: "50px" }}></Input>
          <Input name="title" placeholder="Title"></Input>
          <Input name="interest" placeholder="Interest"></Input>
          <Button className="btn1">+</Button>
          <Button className="btn2">X</Button>
        </Form>
      </template>
      <Title style={{ color: "red", paddingTop: "10px" }}>{active.toLocaleUpperCase()} SERVICE</Title>
      {/* <Tabs>
        <Tab
          className={active == "loan" && "active"}
          onClick={() => setActive("loan")}
        >
          Loan
        </Tab>
        <Tab
          className={active == "saving" && "active"}
          onClick={() => setActive("saving")}
        >
          Saving
        </Tab>
      </Tabs> */}
      <EditContainer className="container">
     {services.length>0?services.map((item,key)=>(
        <Form key={key} className="template">
            <RenderForm item={item} Add={AddHandler} Delete={DeleteHandler}/>
        </Form>


     )):  
     <Form className="template">
     <Input name="sn" placeholder="SN" style={{ width: "50px" }} type="number" ></Input>
     <Input name="title" placeholder="Title"></Input>
     <Input name="interest" placeholder="Interest"></Input>
     <Button onClick={(e) => AddHandler(e)}>+</Button>
     <Button onClick={(e) => DeleteHandler(e)}>X</Button>
   </Form>

     }
        <Btn style={{ marginTop: "30px" }} onClick={updateHandler}>
          Update
        </Btn>
      </EditContainer>
    </Container>
  );
}
const Form = styled.div`
  display: flex;
`;
const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
const Input = styled.input`
  width: 50%;
  padding: 4px;
  margin-left: 20px;
`;
const Tab = styled.div`
  color: white;
  background: grey;
  cursor: pointer;
  border-radius: 12px;
  padding: 5px 14px;
  margin: 5px 5px;
  &.active {
    font-weight: bold;
    color: black;
    background: none;
    color: white;
    background: black;
  }
`;
const Tabs = styled.div`
  margin-top: 40px;
  margin-right: 20px;
  display: flex;
  justify-content: flex-end;
`;
const EditContainer = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: column;
`;
const Services = styled.div`
  width: 400px;
`;
const ServiceTitle = styled.div`
  width: 100%;
  border: none;
  border-top: 1px solid gray;
  cursor: pointer;
  :hover {
    background: gray;
  }
  padding: 5px 2px;
`;
const EditorAddForm = styled.div`
  flex: 1;
  padding: 5px 40px;
  /* background:red; */
`;
const Button = styled.div`
  margin: 5px;
  padding: 10px;
  color: white;
  background: green;
  :hover {
    background: orange;
  }
  cursor: pointer;
`;
const RenderForm=({item,Add,Delete})=>{
    const [sn,setSn]=useState(item.sn)
    const [title,setTitle]=useState(item.title)
    const [interest,setInterest]=useState(item.interest)

    return(<>
        <Input name="sn" placeholder="SN" style={{ width: "50px" }} type="number" onChange={e=>{setSn(e.target.value)}} value={sn}></Input>
        <Input name="title" placeholder="Title"  onChange={e=>{setTitle(e.target.value)}} value={title}></Input>
        <Input name="interest" placeholder="Interest"  onChange={e=>{setInterest(e.target.value)}} value={interest}></Input>
        <Button onClick={(e) => Add(e)}>+</Button>
        <Button onClick={(e) => Delete(e)}>X</Button>
        </>
    )
}
export default Service;
