import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Btn, Title } from '../components/styles'
import DeleteIcon from '@mui/icons-material/Delete';
import { useHistory } from 'react-router';
import { logout, snackmsg } from "../actions/action";
import { host } from '../constant';
import { Modal } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
function PhotoGallery() {
    const history=useHistory()
    const [data,setData]=useState([])
    const [open,setOpen]=useState(false)
    const [name,setName]=useState('')
    const handleClose=()=>{
        setOpen(false)
        setName('')
    }
    const dispatch=useDispatch()
    const token=useSelector(state=>state.token)
    const createHandler=()=>{
        fetch(`${host}/admin/gallery/add`,{
            method:'post',
            headers:{
                'Authorization':`Bearer ${token}`,
                'Content-Type':'application/json'
            },
            body:JSON.stringify({title:name})
        })
        .then(async(res)=>{
            if(res.status==200){
              dispatch(snackmsg("New Album Added"))
              handleClose()
              const response=await res.json()
              setData(data=>[...data,response.data])

              // setReload(reload=>!reload)
            }
            else if(res.status==401){
              dispatch(snackmsg("Session Expired"))
              dispatch(logout())
            }
          }).catch(err=>{
            dispatch(snackmsg("Network Error"))
    
          })
    }
useEffect(()=>{
    fetch(`${host}/admin/gallerys`,{
        method:'get'
    }).then(res=>res.json()).then(data=>{
        setData(data.data)
    })
},[])
const deleteHandler=(id)=>{
  if(window.confirm('Do you want to delete this album?')){
    fetch(`${host}/admin/gallery/${id}`,{
        method:'delete',
        headers:{
            'Authorization':`Bearer ${token}`
        },
    })
    .then(async(res)=>{
        if(res.status==200){
          dispatch(snackmsg("Album Deleted Sucessfully"))
          handleClose()
          const response=await res.json()
        
          setData(data=>data.filter(dat=>{
              return dat._id!=response.data._id
          }))

          // setReload(reload=>!reload)
        }
        else if(res.status==401){
          dispatch(snackmsg("Session Expired"))
          dispatch(logout())
        }
      }).catch(err=>{
        dispatch(snackmsg("Network Error"))

      })
    }

}
    return (
        <Container>
            <Title>Photo Gallery</Title>
            <Btn style={{marginRight:30,marginLeft:"auto",marginBottom:'40px'}} color="tomato" hover="red" onClick={()=>{setOpen(true);}}>+ Add Album</Btn>
            {data.map((item,key)=>(<ListContainer key={key}>
                <List onClick={()=>{history.push(`/gallery/${item._id}`)}}>{item.title}</List>
                <Delete onClick={()=>deleteHandler(item._id)}><DeleteIcon/> </Delete>
            </ListContainer>))}
            <Modal
        style={{
          border: "none",
          outline: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <Card>
          <Title>Add Album</Title>
          <UploadContainer>
            <input style={{padding:"5px 5px",margin:"5px 0 30px",width:'100%'}} placeholder={"Title"} value={name} onChange={e=>setName(e.target.value)} type="text" />
            <Btn style={{ marginTop: "10px" }} onClick={createHandler}>+Add</Btn>
          </UploadContainer>
        </Card>
      </Modal>
        </Container>
    )
}
const Container=styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const ListContainer=styled.div`
margin:8px auto;
display: flex;
width:80%;
border:1px solid grey;

`
const List=styled.div`
flex:1;
font-weight: bold;
cursor: pointer;
padding: 6px 8px;
:hover{
    background: #ebe7df;
}
`
const Delete=styled.div`
color:red;
cursor:pointer;
width:30px;
display: flex;
justify-content: center;
align-items: center;
:hover{
    color:tomato;
}

`
const Card = styled.div`
  padding: 10px 20px;
  width: 500px;
  height: auto;
  background: white;
  outline: none;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
`;
const UploadContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export default PhotoGallery
