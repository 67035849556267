import React, { useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import {Btn, Title} from '../components/styles'
import {useDispatch,useSelector} from 'react-redux'
import {login, snackmsg} from '../actions/action'
import { host } from "../constant"

function Login() {
    const dispatch=useDispatch()
    const history=useHistory()
    const [user,setUser]=useState('')
    const [pwd,setPwd]=useState('')

    const submitHandler=()=>{
        fetch(`${host}/admin/login`,{
            method:"post",
            headers:{
                "Content-Type":"application/json"
            },
            body:JSON.stringify({
                username:user,
                password:pwd
            })
        }).then(async (res)=>{
            let status=res.status
            const response=await res.json()
            if(status==200){
               await localStorage.setItem('token',JSON.stringify({authtoken:response._authtoken}))

               dispatch(login(response._authtoken))
               dispatch(snackmsg("Login Sucess"))
               
            //    await setMessage("Login Sucess")
            //    setOpen(true)
            //    document.location='/'
            }
            else{
                dispatch(snackmsg(response.message))
            //    await setMessage(response.message)
            //    setOpen(true)
            }
        })
    }
    return (
        <Container>

            <Card>
                <Title style={{marginTop:"8px"}}>
                    Login

                </Title>
                <FormContainer>
                    
                    <FormInput>
                        <input value={user} onChange={e=>setUser(e.target.value)} type="text" placeholder="Username" required/>
                    </FormInput>
                    <FormInput>
                        <input value={pwd} onChange={e=>setPwd(e.target.value)} type="password" placeholder="Password" required/>
                    </FormInput>
                    <Btn type="submit" onClick={submitHandler}>
                        Login
                    </Btn>
                </FormContainer>

            </Card>
            
        </Container>
    )
}

export default Login
const Container=styled.div`
width: 100%;
min-height: 100vh;
display: flex;
align-items: center;
justify-content: center;
background:linear-gradient(30deg,red,blue);
`
const Card=styled.div`
width: 340px;
/* height: 300px; */
border-radius:20px;
padding:15px 10px;
/* border: 1px solid; */
/* background: linear-gradient(35deg,rgb(68 68 68/60%),rgb(10 9 9/50%)); */
background:white;
box-shadow: 2px 2px 5px 0 rgba(0,0,0,0.3),
-2px -2px 5px 0 rgba(0,0,0,0.3);
`
const FormContainer=styled.form`
width: 100%;
margin-top: 10px;
display: flex;
flex-direction: column;
justify-content: center;

/* padding:5px; */
`
const FormInput=styled.div`
width: 100%;
display: flex;
justify-content:center;
margin-bottom: 15px;

/* background:red; */
/* height: 2px; */
input{
    outline: none;
    width: 90%;
    border:none;
    
    border:1px solid;
    border-radius:10px;
    padding: 14px 14px;
    font-size: 13px;
}
`