import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { host } from '../constant'
import { Btn } from './styles'
import {logout,snackmsg} from '../actions/action'
function CarouselImage({data,setData}) {
    const [caption,setCaption]=useState(data.caption)
    const dispatch=useDispatch()
    const token=useSelector(state=>state.token)
    const updateHandler=()=>{
        fetch(`${host}/admin/images/updatecaption/${data._id}`,{
            method:'put',
            headers:{
                "Authorization":`Bearer ${token}`,
                "Content-Type":'application/json'
            },
            body:JSON.stringify({caption})

        }).then(async(res)=>{
            if(res.status==200){
              dispatch(snackmsg("Caption Updated"))
            
    
            }
            else if(res.status==401){
              dispatch(snackmsg("Session Expired"))
              dispatch(logout())
            }
            else{
                dispatch(snackmsg("Unexpected Error Occured"))

            }
          }).catch(err=>{
            dispatch(snackmsg("Network Error"))
    
          })
    }
    const DeleteHandler=()=>{
        if(window.confirm(`Do you want to delete ${caption}?? `)){
        fetch(`${host}/admin/images/delete/${data._id}`,{
            method:'delete',
            headers:{
                "Authorization":`Bearer ${token}`,
              
            },
       

        }).then(async(res)=>{
            if(res.status==200){
              dispatch(snackmsg("Image Deleted Sucessfully"))
              setData(items=>items.filter(dat=>{
                  return dat._id!=data._id
              }))
            
    
            }
            else if(res.status==401){
              dispatch(snackmsg("Session Expired"))
              dispatch(logout())
            }
            else{
                dispatch(snackmsg("Unexpected Error Occured"))

            }
          }).catch(err=>{
            dispatch(snackmsg("Network Error"))
    
          })
        }
    }
    return (
        <Container>
        <Image>
            <img src={host+data.url}></img>
        </Image>
        <Content>
            <input type="text" style={{padding:"10px",fontSize:15,margin:"5px 0 30px",width:'100%'}} placeholder={"Caption"} value={caption} onChange={e=>setCaption(e.target.value)}></input>
            <div style={{display:'flex',justifyContent:'center'}}>

            <Btn onClick={()=>updateHandler()} color="green" hover="orange">Update Caption</Btn>
            <Btn onClick={()=>DeleteHandler()} color="red" hover="orange">Delete Image</Btn>
            </div>
        </Content>  
        </Container>
    )
}
const Container=styled.div`
/* width:100%; */
margin:10px 30px;
padding:10px 20px; 
display: flex;
border: 1px solid;
border-radius: 10px;

`
const Image=styled.div`
width:500px;
img{
 
    object-fit: cover;
    display:block;
    width:495px;
    height:auto;
}


`
const Content=styled.div`
display: flex;
flex:1;
margin-left: 10px;
flex-direction: column;
`



export default CarouselImage
