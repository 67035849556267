import React,{useState,useEffect} from 'react'
import styled from 'styled-components'
import {useDispatch,useSelector} from 'react-redux'
import { Btn, Title } from "../components/styles";
import { logout, snackmsg } from "../actions/action";
import { host } from "../constant"
function Comment() {
    const [loading,setLoading]=useState(true)
    const dispatch=useDispatch()
    const [reload,setReload]=useState(false)
    const [data,setData]=useState([])
    const token=useSelector(state=>state.token)
    const dateprocess=(date)=>{
        var a=new Date(date)
        return a.toString()
    }
    const deleteHandler=(id)=>{
        if(window.confirm('Do you want to Delete selected Message?')){
            fetch(`${host}/admin/contacts/${id}`,{
                method:"delete",
                headers:{
                    "Authorization":`Bearer ${token}`
                }
              }).then(async(res)=>{
                  if(res.status==200){
                    dispatch(snackmsg("Message Deleted"))
                    setData(data=>data.filter(dat=>{
                        return dat._id!=id
                    }))
                  
                  }   
                  else if(res.status=422){
                      dispatch(snackmsg("Session Expired"))
                  dispatch(logout())
      
                  }
                  else{
                      dispatch(snackmsg("Error Occured"))
                     
                  }
              })
        }
    }
    useEffect(()=>{
      
        setLoading(true)
        // setData(null)
        fetch(`${host}/admin/contacts`,{
          method:"get",
          headers:{
              "Authorization":`Bearer ${token}`
          }
        }).then(async(res)=>{
            if(res.status==200){
                var response=await res.json()
                
                setData(response.data)
                setLoading(false)
            }   
            else if(res.status=422){
                dispatch(snackmsg("Session Expired"))
            dispatch(logout())

            }
            else{
                dispatch(snackmsg("Error Occured"))
                setLoading(false)
            }
        })
    
      },[reload])
    return (
        <Container>
             <Title style={{ marginBottom: "10px" }} color="red">
        Comments
      </Title>
      {
          loading?"Loading...":<>
          <Body>
              {data.length==0&& "No Comments"}
              {
                  data.map((item,key)=>(      <CommentContainer key={key}>
                    <Detail><span>Name: </span> {item.name}</Detail>
                    <Detail><span>Email: </span> <a href={`mailto:${item.email}`}>{item.email}</a></Detail>
                    <Detail><span>Phone: </span> <a href={`tel:${item.phone}`}>{item.phone}</a></Detail>
                    <Detail><span>Subject: </span> {item.subject}</Detail>
                    <Detail><span>Date: </span> {dateprocess(item.createdAt)}</Detail>
                    <Detail><span>Message: </span> {item.message}</Detail>
                    <Btn onClick={()=>{deleteHandler(item._id)}}>Delete</Btn>
                </CommentContainer>))

              }
      
          </Body>
          
          </>
      }

            </Container>
    )
}

export default Comment
const Container=styled.div`
 flex: 1;
  width: 100%;
  height:100vh;
  display:flex;
  flex-direction:column;
`
const Body=styled.div`
overflow-y:scroll;
flex: 1;
padding:10px 70px;
width: 100%;
display:flex;
flex-direction:column;
@media(max-width:1200px){
    padding:10px 30px;

}
`   
const CommentContainer=styled.div`
margin-bottom:7px;
border:1px solid grey;
border-radius:10px;
width: 100%;
padding:10px 20px;

`
const Detail=styled.div`
font-size:18px;
line-height:26px;
span{
    font-weight:bold;
}
`