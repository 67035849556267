import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Btn, Title } from "../components/styles";
import { host } from "../constant";
import {snackmsg,logout} from '../actions/action'
import {useSelector,useDispatch} from 'react-redux'
import TextBox from '../components/TextBox'
function About() {
    const [text,setText]=useState('')
    const token=useSelector(state=>state.token)
    const dispatch=useDispatch()
    useEffect(()=>{
        fetch(`${host}/api/about`,{
            method:'get'
        }).then(res=>res.json()).then(result=>setText(result.content)).catch(err=>{})
    },[])
    const updateHandler=()=>{
        fetch(`${host}/admin/about`,{
            method:"put",
            headers:{
                'Content-Type':'application/json',
                'Authorization':`Bearer ${token}`
            },
            body:JSON.stringify({content:text})
        }).then(res=>{
            if(res.status==200){
                dispatch(snackmsg("About us Updated"))
            }
            else if(res.status=401){
                dispatch(snackmsg("Session Expired"))
                dispatch(logout())
            }
            else{
                dispatch(snackmsg("Error Occured"))

            }
        }).catch(err=>{
            dispatch(snackmsg("Error Occured"))

        })
        
    }
    return (
        <Container>
            <Title>About us</Title>
            <TextBox currentValue={text} onChange={setText} height={1000}/>
            <Btn onClick={updateHandler}>Update</Btn>
        </Container>
    )
}

export default About
const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;