import { Modal } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styled from 'styled-components'
import { Btn, Title } from '../components/styles'
import { logout, snackmsg } from "../actions/action";
import { host } from '../constant'
import CarouselImage from '../components/CarouselImage'
function Carousel() {
    const dispatch=useDispatch()
    const [data,setData]=useState([])
    const [open,setOpen]=useState(false)
    const token=useSelector(state=>state.token)

    const [caption,setCaption]=useState("")
    
    
    const handleClose=()=>{
        setOpen(false)
        if(fileRef.current){


            fileRef.current.files=null
          }
    }
    const fileRef=useRef()
    useEffect(()=>{
        fetch(`${host}/admin/carouselimages`,{
            method:'get'
        }).then(res=>res.json()).then(data=>{
            setData(data.data)
        })
    },[])
    const uploadHandler=()=>{
        if(fileRef.current.files.length>0){
            let formdata=new FormData()
            var i;
            for(i=0;i<fileRef.current.files.length;i++){

              formdata.append('post-image',fileRef.current.files[i]);
            }
            formdata.append('caption',caption);
            
            fetch(`${host}/admin/addimage`,{
              method:"post",
              headers:{
                authorization:`Bearer ${token}`
              },
              body:formdata
            }).then(async(res)=>{
              if(res.status==200){
                dispatch(snackmsg("New Image Added"))
                handleClose()
                const response=await res.json()
                setData(data=>[...data,...response.data])

                // setReload(reload=>!reload)
              }
              else if(res.status==401){
                dispatch(snackmsg("Session Expired"))
                dispatch(logout())
              }
            }).catch(err=>{
              dispatch(snackmsg("Network Error"))
      
            })
          }
          

    }
    return (
        <Container>
            <Title>Images</Title>
            <Btn style={{marginRight:30,marginLeft:"auto",marginBottom:'40px'}} color="tomato" hover="red" onClick={()=>{setOpen(true);}}>+ Add Image</Btn>
            {
                data?.map((item,key)=>(<CarouselImage data={item} key={key} setData={setData}/>))
            }
            <Modal
        style={{
          border: "none",
          outline: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <Card>
          <Title>Add Image</Title>
          <UploadContainer>
            <input style={{padding:"5px 5px",margin:"5px 0 30px",width:'100%'}} placeholder={"Caption"} value={caption} onChange={e=>setCaption(e.target.value)} type="text" />
            <input ref={fileRef} type="file" multiple />
            <Btn style={{ marginTop: "10px" }} onClick={uploadHandler}>Upload</Btn>
          </UploadContainer>
        </Card>
      </Modal>
        </Container>
    )
}
const Container=styled.div`
 flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`
const Card = styled.div`
  padding: 10px 20px;
  width: 500px;
  height: auto;
  background: white;
  outline: none;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
`;
const UploadContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;

export default Carousel
