import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Btn, Title } from "../components/styles";
import { host } from "../constant"
import {useDispatch,useSelector} from 'react-redux'
import { logout, snackmsg } from "../actions/action";
import { Modal } from "@mui/material";
function Announcement(props) {
  const dispatch=useDispatch()
  const [loading,setLoading]=useState(true)
  const [data,setData]=useState(null)
  const [open,setOpen]=useState(false)
  const [reload,setReload]=useState(false)
  const token=useSelector(state=>state.token)
  const fileRef=useRef(null)
  useEffect(()=>{
    var timestamp = new Date().getTime();  
    setLoading(true)
    // setData(null)
    fetch(`${host}/admin/announce`,{
      method:"get"
    }).then(res=>res.json()).then(data=>{
      if(data.data){

        setData(data.data.url+`?t=${timestamp}`)
      }
      else{
        setData(null)
      }
      setLoading(false)
    })

  },[reload])
  const handleClose = () => {
setOpen(false)
if(fileRef.current){


  fileRef.current.files=null
}

  };
  const RemoveAnnouncement=()=>{
    if(window.confirm("Do you want to remove current announcement?")){

      fetch(`${host}/admin/removeannouncemnet`,{
        method:"delete",
        headers:{
          authorization:`Bearer ${token}`
        }
        
      }).then(res=>{
          if(res.status==200){

            dispatch(snackmsg("Announcement Removed"))
            setReload(reload=>!reload)
          }
          else if(res.status==401){
            dispatch(snackmsg("Session Expired"))
            dispatch(logout())
          }
  
        
  
      })
      .catch(err=>{
        dispatch(snackmsg("Network Error"))

      })
    }
  }
  const uploadHandler=()=>{
    if(fileRef.current.files[0]){
      let formdata=new FormData()
      formdata.append('post-image',fileRef.current.files[0]);
      fetch(`${host}/admin/updateannouncements`,{
        method:"post",
        headers:{
          authorization:`Bearer ${token}`
        },
        body:formdata
      }).then(res=>{
        if(res.status==200){
          dispatch(snackmsg("New Announcement Added"))
          handleClose()
          setReload(reload=>!reload)
        }
        else if(res.status==401){
          dispatch(snackmsg("Session Expired"))
          dispatch(logout())
        }
      }).catch(err=>{
        dispatch(snackmsg("Network Error"))

      })
    }
    
  }
  return (
    <Container>
      {loading?<>
      </>:
<>

      
      <Title style={{ marginBottom: "10px" }} color="red">
        Announcement
      </Title>
      <AnnoucementContainer>
        {
          data?
        <img width="600px" src={`${host}${data}`}/> :


        <NoAnnouncement width="600px">No Annoucement</NoAnnouncement>
        }
        <Buttons>
          <Btn color="#03d87f" hover="blue" onClick={()=>setOpen(true)}>
            + New
          </Btn>
          <Btn color="tomato" hover="blue" onClick={()=>RemoveAnnouncement()}>
            Remove
          </Btn>
        </Buttons>
      </AnnoucementContainer>
      </>
      }
      <Modal
        style={{
          border: "none",
          outline: "none",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        open={open}
        onClose={handleClose}
      >
        <NewAnnoucementCard>
          <Title>New Announcement</Title>
          <UploadContainer>
            <input ref={fileRef} type="file" />
            <Btn style={{ marginTop: "10px" }} onClick={uploadHandler}>Upload</Btn>
          </UploadContainer>
        </NewAnnoucementCard>
      </Modal>
    </Container>
  );
}

export default Announcement;
const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;
const AnnoucementContainer = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const Buttons = styled.div`
  margin-top: 20px;
  display: flex;
  gap: 20px;
`;
const NoAnnouncement = styled.div`
  width: 600px;
  height: 320px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
`;
const NewAnnoucementCard = styled.div`
  padding: 10px 20px;
  width: 500px;
  height: auto;
  background: white;
  outline: none;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);
`;
const UploadContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
`;
