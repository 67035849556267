import React from 'react'
import { Btn, Title } from '../components/styles'
import styled from "styled-components";
import { useState } from 'react';
import { Modal } from '@mui/material'
import { useEffect } from 'react';
import { snackmsg } from "../actions/action";
import { host } from '../constant';
import { useSelector, useDispatch } from 'react-redux';
import AccountOpen from '../components/ApplicationResponse/AccountOpen';
const dateprocess = (date) => {
    var a = new Date(date)
    return a.toString()
}
function Form() {
    const [active, setActive] = useState('open-account')
    const [data, setData] = useState([])
    const token = useSelector(state => state.token)
    const [open, setOpen] = useState(false)
    const [current,setCurrent]=useState(null)
    const dispatch = useDispatch()
    const handleClose = () => {
        setOpen(false)
    }

    useEffect(async () => {
        var forms = await fetch(`${host}/admin/form/${active}`, {
            method: "get",
            headers: {
                authorization: `Bearer ${token}`
            },
        })
        forms = await forms.json()
        console.log(forms.forms)
        await setData(forms.forms)
    }, [active])
    const deleteHandler = async (id) => {
        if (window.confirm("Do you want to delete selected form?")) {

            var res = await fetch(`${host}/admin/form/${active}?id=${id}`, {
                method: "delete",
                headers: {
                    authorization: `Bearer ${token}`
                },
            })
            if (res.status == 200) {
                dispatch(snackmsg("Form Deleted"))
                setData(data => data.filter(dat => {
                    return dat._id != id
                }))

            }

            else {
                dispatch(snackmsg("Error Occured"))

            }
        }
    }
    return (
        <>
    

        <Container open={open}>
      
            <Title>Applications</Title>
            <Tabs>
                <Tab className={active == "open-account" && "active"} onClick={() => setActive("open-account")}>Account Open</Tab>
                <Tab className={active == "kyc" && "active"} onClick={() => setActive("kyc")}>Account Open</Tab>

            </Tabs>
            <Modal
                style={{
                    border: "none",
                    outline: "none",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
                open={open}
                onClose={handleClose}
            >
                <Card>
                    <ApplicationView>
               {
                active=="open-account"?<AccountOpen data={current}/>:""
               }
                    </ApplicationView>

                </Card>
            </Modal>
            {data.map((item, key) => (<List>
                <Photo src={host + item.form.photo}></Photo>
                <Name>Applicant Name: {item.form.nameEng}<br></br>Submitted At: {dateprocess(item.createdAt)}</Name>
                <Buttondiv>
                    <Btn onClick={() => {setOpen(true);setCurrent(item.form)}} color="green" hover="orange">View Application</Btn>
                    <Btn color="red" hover="orange" onClick={() => deleteHandler(item._id)}>Delete</Btn>
                </Buttondiv>
            </List>))}
        </Container>
        </>
    )
}

export default Form
const Container = styled.div`
position:relative;
  flex: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

    
  
  
 
`

const Tab = styled.div`
color:white;
    background:grey;
cursor:pointer;
border-radius:12px;
padding: 5px 14px;
margin:5px 5px;
&.active{
    font-weight: bold;
    color:black;
    background:none;
    color:white;
background:black;
}

`
const List = styled.div`
height:200px;
margin:10px 30px;
padding:10px;
border:1px solid;
border-radius:10px;
display:flex;
align-items:center;
`
const Tabs = styled.div`
margin-top: 40px;
margin-right: 20px;
display: flex;
justify-content: flex-end;
`
const Photo = styled.div`
width:180px;
height:180px;
border:1px solid black;
background:url('${props => props.src}');
background-size:cover;
background-position:center;
background-repeat:no-repeat;
`
const Name = styled.div`
color:black;
font-weight:bold;
font-size:15px;
margin-left:40px;
height:100%;
margin-right:auto;
max-width:700px;
`
const Buttondiv = styled.div`
display:flex;
flex-direction:column;
width:300px;
justify-content:center;
align-items:center;
`
const Card = styled.div`
overflow:scroll;
  padding: 10px 20px;
  width: 95%;
  height:95vh;
  border-radius:20px;
  background: white;
  outline: none;
  box-shadow: 2px 2px 5px 0 rgba(0, 0, 0, 0.5);

`;
const ApplicationView = styled.div`
width:100%;
display:flex;
`