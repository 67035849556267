import React from 'react'
import styled from 'styled-components'

function Images(props) {
    return (
        <Container {...props}>
            <img src={props.src}/>
            {props.children}

                    
        </Container>
    )
}

export default Images
const Container=styled.div`
width:${props=>props.width?props.width:"192px"};
height:calc(${props=>props.width?props.width:"192px"} * 9/16);
display: flex;
justify-content: center;
position: relative;

img{
    display: block;
    width: 100%;
    height: auto;
    object-fit: contain;
    
}
`

