import React from 'react'
import styled from 'styled-components'
import { host } from '../../constant'

function AccountOpen({data}) {
  return (
   <>
   <DataDiv>
    <Title>Personal Information</Title>
    <Dat>Name (In English): {data.nameEng}</Dat>
    <Dat>Name (In Nepali): {data.nameNe}</Dat>
    <Dat>Date of Birth (BS): {data.dob}</Dat>
    <Dat>Father/Husband Name: {data.fatherName}</Dat>
    <Dat>Grand Father Name: {data.grandFatherName}</Dat>
    {data.phoneNumber && <Dat>Phone Number: {data.phoneNumber}</Dat>}
    <Dat>Mobile Number: {data.mobileNumber}</Dat>
    <Title>Citizen/Passport</Title>
    <Dat>Citizenship/Passport No: {data.identifyNo}</Dat>
    <Dat>Issued Office: {data.issuedOffice}</Dat>
    <Dat>Issued Date: {data.issuedDate}</Dat>
    <Title>Permanent Address</Title>
    <Dat>District:{data.perDistrict}</Dat>
    <Dat>Local Level:{data.perLocalLevel}</Dat>
    <Dat>Ward:{data.perWard}</Dat>
    <Dat>Tole:{data.perTole}</Dat>
    <Title>Current Address</Title>
    {
        data.curLocalLevel=='undefined'?<Dat>Same As Permanent</Dat>:<>
          <Dat>District:{data.curDistrict}</Dat>
    <Dat>Local Level:{data.curLocalLevel}</Dat>
    <Dat>Ward:{data.curWard}</Dat>
    <Dat>Tole:{data.curTole}</Dat></>
    }
    {
        data.nomineeName!="" &&<>
        
        <Title>Nominee Details</Title>
        <Dat>Name:{data.nomineeName}</Dat>
        <Dat>Relation:{data.nomineeRelation}</Dat>
        <Dat>Address:{data.nomineeAddress}</Dat>
        <Dat>Contact:{data.nomineeContact}</Dat>
        </>
    }
    <Title>Account Details</Title>
    <Dat>Account Operation:{data.accOperation}</Dat>
    <Dat>Period:{data.accPeriod}</Dat>
    <Dat>Initial Deposit:Rs. {data.initDeposit}</Dat>

    <Dat>Account Holder is Minor? {data.isMinor=="null"||data.isMinor=="0"?"No":"Yes"}</Dat>
    {data.isMinor=="1" && <>
    <Dat>Initial Deposit:Rs. {data.accOptName}</Dat>
    <Dat>Initial Deposit:Rs. {data.accOptContact}</Dat>
    </>}
 <Title>Photo</Title>
 <Photo src={host+data.photo}/>
 <Title>Citzenship/Passport</Title>
 <Photo src={host+data.identityPhoto}/>

   </DataDiv>

   
   </>
  )
}

export default AccountOpen
const Title=styled.div`
font-size:20px;
color:red;
margin-bottom:10px;
`
const Dat=styled.div
`font-size:15px;
color:black;
`

const Photo=styled.img`
width:${props=>props.width?props.width:200}px;
height:${props=>props.height?props.height:200}px;
object-fit:contain;
margin-top:10px;
margin-bottom:10px;
`
const DataDiv=styled.div`
flex:1;
margin-right:20px;
font-weight:bold;
`
