import React from 'react'
import styled from 'styled-components'
import NotificationsIcon from '@mui/icons-material/Notifications';
import EventIcon from '@mui/icons-material/Event';
import PeopleIcon from '@mui/icons-material/People';
import {Link, useHistory} from 'react-router-dom'
import ShowChartIcon from '@mui/icons-material/ShowChart';
import InfoIcon from '@mui/icons-material/Info';
import LockIcon from '@mui/icons-material/Lock';
import {Announcement,Note, AnnouncementSharp, Badge, Download, Image, PermMedia,Comment, CalendarToday} from '@mui/icons-material';
import {useDispatch} from 'react-redux' 
import WorkIcon from '@mui/icons-material/Work';
import MessageIcon from '@mui/icons-material/Message';
import {logout} from '../actions/action'
import SavingsIcon from '@mui/icons-material/Savings';
import BungalowIcon from '@mui/icons-material/Bungalow';
function Sidebar() {
    const history=useHistory()
    const dispatch=useDispatch()
    const logoutHandler=()=>{
        if(window.confirm("Do you want to logout?")){

            dispatch(logout())
        }
    //    await localStorage.removeItem('token')
    //    document.location='/'

    }
    return (
        <Container>
            <Brand>
            <Logo>
                <img src="/logo.png" alt="LOGO"/>
            </Logo>
            <CompanyName>Shankhadhar Saccos</CompanyName>
            </Brand>
            <NavItems to="/about">
                <Icon>
                <InfoIcon/>
                </Icon>
                <Title>
                  About

                </Title>
            </NavItems>
            <NavItems to="/message">
                <Icon>
                <MessageIcon/>
                </Icon>
                <Title>
                  Message

                </Title>
            </NavItems>
      
            <NavItems to="/announcement">
                <Icon>
                <NotificationsIcon/>
                </Icon>
                <Title>
                    Announcement

                </Title>
            </NavItems>
            <NavItems to="/stats">
                <Icon>
                <ShowChartIcon/>
                </Icon>
                <Title>
                 Stats

                </Title>
            </NavItems>
            <NavItems to="/service/saving">
                <Icon>
                <SavingsIcon/>
                </Icon>
                <Title>
                   Saving Service

                </Title>
            </NavItems>
            <NavItems to="/service/loan">
                <Icon>
                <BungalowIcon/>
                </Icon>
                <Title>
                   Loan Service

                </Title>
            </NavItems>
            <NavItems to="/board">
                <Icon>
                <Badge/>
                </Icon>
                <Title>
                   Board Members

                </Title>
            </NavItems>
            <NavItems to='/staffs'>
                <Icon>
                <PeopleIcon/>
                </Icon>
                <Title>
                   Staffs

                </Title>
            </NavItems>
            <NavItems to="/news">
                <Icon>
                <AnnouncementSharp/>
                </Icon>
                <Title>
               News

                </Title>
            </NavItems>
            <NavItems to="/images">
                <Icon>
                <Image/>
                </Icon>
                <Title>
             Images

                </Title>
            </NavItems>
            <NavItems to="/gallery">
                <Icon>
                <PermMedia/>
                </Icon>
                <Title>
           Photo Gallery

                </Title>
            </NavItems>
            <NavItems to="/downloads">
                <Icon>
                <Download/>
                </Icon>
                <Title>
           Downloads

                </Title>
            </NavItems>
            <NavItems to="/events">
                <Icon>
                <CalendarToday/>
                </Icon>
                <Title>
                  Events

                </Title>
            </NavItems>
            <NavItems to="/comments">
                <Icon>
                <Comment/>
                </Icon>
                <Title>
                  Comments

                </Title>
            </NavItems>
            <NavItems to="/applicationform">
                <Icon>
                <Note/>
                </Icon>
                <Title>
                  Application Forms

                </Title>
            </NavItems>
            <NavItems onClick={logoutHandler}>
                <Icon>
                    <LockIcon/>
                
                </Icon>
                <Title>
                  Logout

                </Title>
            </NavItems>
          

        </Container>
    )
}

export default Sidebar
const Container=styled.div`
position: sticky;
top:0;
left:0;
height: 100vh;
background:#0a0e40;
width: 250px;
display: flex;
flex-direction: column;
color:white;
padding: 10px 0;
@media print{
    display:none;
}
`
const Brand=styled.div`
height: 30px;
display: flex;
/* justify-content: center; */
align-items: center;
margin-bottom: 20px;
padding: 2px 10px;
`
const Logo=styled.div`
height: 100%;
width: 50px;
display: flex;
align-items: center;
margin-right: 8px;
img{
    display: block;
    height: auto;
    width: 53px;
    object-fit: contain;
}
`
const CompanyName=styled.div`
color: white;
font-size:20px;
font-weight:600;
`
const NavItems=styled(Link)`
height:40px;
display: flex;
align-items: center;
cursor: pointer;
color:white;
text-decoration:none;
margin-top: 0px;
padding: 2px 10px;
transition: .4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
:hover{
    background:#4b7cb9;
}

`
const Icon=styled.span`
width: 50px;
display: flex;
align-items: center;
justify-content: center;
margin-right: 8px;

`
const Title=styled.span`
flex: 1;
display: block;
font-size:19px;

`