import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Btn, Title } from "../components/styles";

import * as Icons  from '@mui/icons-material';

import { host } from "../constant"
import {useDispatch,useSelector} from 'react-redux'
import { logout, snackmsg } from "../actions/action";
import { Autocomplete, TextField } from "@mui/material";
function Data(props) {
  const dispatch=useDispatch()
  const token=useSelector(state=>state.token)
  const [data,setData]=useState(null)

  const [loading,setLoading]=useState(true)
  useEffect(()=>{
fetch(`${host}/admin/ourstats`,{
  method:'get',
}).then(res=>res.json()).then(data=>{

  if(data.data){
      setData(data.data.stats)
     
    }
    setLoading(false)
})

  },[])
  const addHandler=()=>{
    if(data.length<8){

      setData(olddata=>[...olddata,{title:'Title',value:'value',icon:'FavoriteBorder'}])
    }
  }
  const removeHandler=(index)=>{
    if(data.length>=5){
      if(window.confirm('Removed Selected Item?')){

        setData(olddata=>{
          const newData=[...olddata]
          newData.splice(index,1)
          return newData
        })
      }
    }

  }
  const updateHandler=()=>{
    const newData=[...data]
  for(var i=0;i<newData.length;i++){
    if(!data[i].icon||data[i].icon.trim()==""){
      data[i].icon="FavoriteBorder"
    }
  }
  setData(newData)  

    fetch(`${host}/admin/updatestats`,{
      method:"put",
      headers:{
        "Content-Type":'application/json',
        authorization:`Bearer ${token}`
      },
      body:JSON.stringify({
        data:data
      })

    }).then(res=>{
      if(res.status===200){
          dispatch(snackmsg("Stats Updated"))
          
      }
      else if(res.status===401){
          dispatch(snackmsg("Session Expired"))
          dispatch(logout())
      }
  }).catch(err=>{
      dispatch(snackmsg("Network Error"))
  })
  }
  const valueChanged=(value,index,key)=>{

    setData(olddata=>{
      const newData=[...olddata]
      newData[index][key]=value
      return newData
    })

  }
  return (
    <Container>
      <Title style={{ marginBottom: "10px" }} color="red">
        Our Stats
      </Title>
      {
        loading?<></>:
      <>
      <EventDatasContainer>
        {
          data.map((item,index)=>{
            const StatIcon=item.icon?Icons[item.icon]:Icons['FavoriteBorder']
            return(
            <EventData>
 
            <Icone>
              <StatIcon/>
            </Icone>
            <Autocomplete
    
    options={Object.keys(Icons)}
    getOptionLabel={(option) => option}
    value={item.icon}
  
    onChange={(e,value)=>valueChanged(value,index,'icon')}

    renderInput={(params) => <TextField   style={{border:"none",outline:'none',color:'white'}}  {...params}  />}
  />
            <Text value={item.value} onChange={(e)=>valueChanged(e.target.value,index,'value')}  type="text" required></Text>
            <Text value={item.title} onChange={(e)=>valueChanged(e.target.value,index,'title')}  type="text" required></Text>
            {/* <EventTitle>{item.title}</EventTitle> */}
            <Close onClick={()=>removeHandler(index)}>
              X
            </Close>
          </EventData>
          )})
        }
       
      </EventDatasContainer>
      <Btn onClick={addHandler} type="submit" style={{ marginTop: "14px" }}>Add New</Btn>
      <Btn onClick={updateHandler} type="submit" style={{ marginTop: "14px" }}>Update</Btn>
      </>
      }
    </Container>
  );
}

export default Data;
const Container = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
`;
const EventDatasContainer = styled.form`
  width: 100%;
  height: auto;
  display: flex;
  /* flex-direction: column; */
  flex-wrap:wrap;
  /* align-items: center; */
  padding: 10px 20px;
  background: rgb(32, 141, 255);
`;
const EventData = styled.div`
  width: 25%;
  display: flex;
  position:relative ;
  flex-direction: column;
`;

const Icone = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    height: 88px;
    color: white;
    width: auto !important;
    /* @media(max-width:1440px){
  height:68px;
}
@media(max-width:1044px){
  height:55px;
}
@media(max-width:768px){
  height:45px;
}
@media(max-width:425px){
  height:45px;
}
@media(max-width:375px){
  height:39px;
} */
  }
`;
const Text = styled.input`
  color: white;
  width: 100%;
  text-align: center;
  font-size: 24px;
  margin-top: 10px;
  position: relative;
  bottom: 10px;
  font-weight: 600;
  background: transparent;
  outline: none;
  border: none;
  @media (max-width:800px){
    font-size:20px;
}
  @media (max-width:600px){
    font-size:18px;
}
  @media (max-width:450px){
    font-size:16px;
}
  /* font-family: 'Montserrat',sans-serif; */
  /* @media (max-width:1440px){
    font-size:43px;
}

@media (max-width:900px){
    font-size:26px;
}
@media (max-width:768px){
    font-size:22px;
}
@media (max-width:425px){
    font-size:18px;
}
@media (max-width:375px){
    font-size:16px;
} */
`;
const EventTitle = styled.div`
  color: white;
  width: 100%;
  text-align: center;
  font-size: 18px;

  text-transform: uppercase;
  position: relative;
  bottom: 15px;
  /* @media (max-width:1440px){
    font-size:15px;
}
@media (max-width:1044px){
    font-size:13px;
}
@media (max-width:900px){
    font-size:11.5px;
}
@media (max-width:768px){
    font-size:10px;
}
@media (max-width:425px){
    font-size:8.5px;
}
@media (max-width:375px){
    font-size:7.5px;
} */
`;
const Close=styled.div`
position:absolute;
top:10px;
right:10px;
cursor:pointer;
font-weight:bold;
color:white;
`

