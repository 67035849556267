import React, { useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { host } from '../constant';
import { useSelector } from 'react-redux';

export default function App(props) {
  const editorRef = useRef(null);
  const token=useSelector(state=>state.token)
  const uploadHandler=(blobInfo, success, failure, progress)=>{
    var xhr, formData;

    xhr = new XMLHttpRequest();
    xhr.withCredentials = false;
    xhr.open('POST', `${host}/admin/uploadimage`);
    xhr.setRequestHeader('Authorization',`Bearer ${token}`)
  
    xhr.upload.onprogress = function (e) {
      progress(e.loaded / e.total * 100);
    };
  
    xhr.onload = function() {
      var json;
  
      if (xhr.status === 403) {
        failure('HTTP Error: ' + xhr.status, { remove: true });
        return;
      }
  
      if (xhr.status < 200 || xhr.status >= 300) {
        failure('HTTP Error: ' + xhr.status);
        return;
      }
  
      json = JSON.parse(xhr.responseText);
  
      if (!json || typeof json.link != 'string') {
        failure('Invalid JSON: ' + xhr.responseText);
        return;
      }
  
      success(host+json.link);
    };
  
    xhr.onerror = function () {
      failure('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
    };
  
    formData = new FormData();
    formData.append('image', blobInfo.blob(), blobInfo.filename());
  
    xhr.send(formData);

  }
  const log = () => {
    if (editorRef.current) {
      console.log(editorRef.current.getContent());
    }
  };
  return (
    <>
      <Editor apiKey="9j3bnzd628qziqaicjidu8q3eb30kklygnivik7egjnhtt9e"
        onInit={(evt, editor) => editorRef.current = editor}
        value={props.currentValue}
        //  initialValue={props.currentValue}
    
        onEditorChange={e=>{props.onChange(e)}}
        init={{
          height: props.height?props.height:330,
          menubar: false,
          images_upload_handler:uploadHandler,
                    plugins: [
            "advlist autolink link  lists charmap print preview hr anchor pagebreak spellchecker link",
            "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking",
            "save table contextmenu directionality emoticons template paste textcolor image media",
        ],
        toolbar: "insertfile undo redo | fontselect | fontsizeselect| link  styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | link image media | print preview media fullpage | forecolor backcolor emoticons",
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:0.9rem }',
          image_class_list: [
            {title: 'Responsive', value: 'img-responsive'}
        ],
          
          fontsize_formats:
    "0.1rem 0.2rem 0.3rem 0.4rem 0.5rem 0.6rem 0.7rem 0.8rem 0.9rem 1.0rem 1.1rem 1.2rem 1.3rem 1.4rem 1.5rem 1.6rem 1.7rem 1.8rem 1.9rem 2.0rem 2.1rem 2.2rem 2.3rem 2.4rem 2.5rem 2.6rem 2.7rem 2.8rem 2.9rem 3.0rem 3.1rem 3.2rem 3.3rem 3.4rem 3.5rem 3.6rem 3.7rem 3.8rem 3.9rem 4.0rem 4.1rem 4.2rem 4.3rem 4.4rem 4.5rem 4.6rem 4.7rem 4.8rem 4.9rem"
        }}
      />

    </>
  );
}