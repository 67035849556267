import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Title,Btn} from './styles';
import Images  from './Image';
import { host } from "../constant"
import {useSelector,useDispatch} from 'react-redux'
import { logout, snackmsg } from '../actions/action';
function Team(props) {
  const dispatch=useDispatch()
  const token=useSelector(state=>state.token)
  const [active,setActive]=useState(true)
    const [name,setName]=useState("")
    const [role,setRole]=useState("")
    const [tel,setTel]=useState("")
    const [mobile,setMobile]=useState("")
    const [email,setEmail]=useState("")
    const fileRef=useRef(null)
    const [src,setSrc]=useState('')
    const unauthorized=()=>{
      dispatch(snackmsg("Session Expired"))
      dispatch(logout())
    }
    useEffect(()=>{
      if(props.mode=="edit"){
        setSrc(`${host}/${props.data.image}`)
        setName(props.data.name)
        setRole(props.data.role)
        setTel(props.data.contact[0].link)
        setMobile(props.data.contact[1].link)
        setEmail(props.data.contact[2].link)
      }  
    },[])
    
 const updateThumb=()=>{
    if(fileRef.current){
            
        const [file]=fileRef.current.files
   
if(file){

 setSrc(URL.createObjectURL(file))
}
else{
  setSrc("")

}
}
 } 
const add=async ()=>{

  if(!name || !role){
    return
  }
  if(!active) return
  setActive(false)
  if(fileRef.current.files.length>0){
    const newFormdata=new FormData()

    newFormdata.append('picture',fileRef.current.files[0])
    newFormdata.append('name',name)
    var image=""
  const upload=await fetch(`${host}/admin/uploadmemberphoto`,{
      method:"post",
      headers:{
        authorization:`Bearer ${token}`
      },
      body:newFormdata
    })
    if(upload.status==401){
      return unauthorized()
    }
    if(upload.status==200){
      image=(await upload.json()).path
      
    

    }
  }
  fetch(`${host}/admin/${props.endpoint}`,{
    method:props.method,
    body:JSON.stringify({
      name,
      role,
      tel,
      mobile,
      email,
      type:props.type,
      image
      

    }),
    headers:{
      'Content-Type':'application/json',
      authorization:`Bearer ${token}`
    }


  }).then(async(res)=>{
    if(res.status==200){
        
        
        var response=await res.json()
        if(props.method=="post"){
          dispatch(snackmsg("Added Sucessfully"))
          props.update(data=>[...data,response.data])
        } 
        else{
          dispatch(snackmsg("Updated Sucessfully"))
          props.update(data=>data.map(item=>{
            return item._id==response.data._id?response.data:item
        }))
        
      }
      props.close()
        
        // window.location.reload(false)
    }
    else if(res.status==401){
      unauthorized()
    }
    else if (res.status==400){
      dispatch(snackmsg("Name Already Exists"))
      setActive(true)
    }
  })
  .catch(err=>{ 
    console.log(err)
    // dispatch(snackmsg("Network Error"))

  })
}
    return (
        <>
          <Title>{props.title}</Title>
          <Form>
            <InputContainer>
              <Label>Name</Label>
              <input value={name} onChange={e=>setName(e.target.value)} type="text" placeholder="Name" />
            </InputContainer>
            <InputContainer>
              <Label>Role</Label>
              <input value={role} onChange={e=>setRole(e.target.value)}  type="text" placeholder="Role" />
            </InputContainer>
            <InputContainer>
              <Label>Tel(res)</Label>
              <input value={tel} onChange={e=>setTel(e.target.value)}  type="text" placeholder="Tel(Res)" />
            </InputContainer>
            <InputContainer>
              <Label>Mobile</Label>
              <input value={mobile} onChange={e=>setMobile(e.target.value)}  type="text" placeholder="Mobile Number" />
            </InputContainer>
            <InputContainer>
              <Label>Email</Label>
              <input value={email} onChange={e=>setEmail(e.target.value)}  type="text" placeholder="Email Address" />
            </InputContainer>
            <InputContainer>
              <Label>Photo</Label>
              <input accept="image/*" onChange={updateThumb} ref={fileRef} style={{ border: "none" }} type="file" />
              <div
                style={{
                  marginTop: "2px",
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <img
               
                  width="40%"
                  src={src}
                  onError={()=>setSrc("")}
                />
              </div>
            </InputContainer>
            <InputContainer>
              <Btn onClick={add}>{props.btntext}</Btn>
            </InputContainer>
          </Form>
            
        </>
    )
}

export default Team
const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */

  /* gap:10px; */
`;
const InputContainer = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  /* width:${(props) => (props.width ? props.width : "100%")}; */
  input {
    /* flex: 1; */
    outline: none;
    width: 100%;
    padding: 10px 8px;
    /* border:none; */
    font-size: 15px;
    border-bottom: 1px solid black;
    border-radius: 12px;
  }
`;
const Label = styled.div`
  display: flex;
  align-items: center;
  padding-right: 4px;
  color: red;
  font-weight: 600;
  font-size: 15px;
`;
